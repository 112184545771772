import { images } from '@/asset';
import ModalConfirm from '@/components/ModalConfirm';
import { PricingPlan, PricingTag, Subscription } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { wanningDowngrade } from '@/constants/pricing';
import { openCrisp } from '@/helpers';
import { capitalizeFirstLetter } from '@/helpers/string';
import { dateToTimeStamp } from '@/helpers/time';
import ChargeActionBtn from '@/pages/PricingPlan/components/ChargeActionBtn';
import { PricingCardStyled } from '@/pages/PricingPlan/components/PricingCard/styled';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dashboardDataSelector } from '@/redux/slice/dashboard.slice';
import { IFeature } from '@/types/components/pricing';
import { Badge, Banner, Button, Card, Divider, Icon, Image, List, Text, Tooltip } from '@shopify/polaris';
import { CheckIcon, InfoIcon } from '@shopify/polaris-icons';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

interface IProps {
  pricing: number;
  selected?: boolean;
  title: string;
  features: IFeature[];
  discount?: number;
  subscription: Subscription;
  description: string;
  plan: PricingPlan;
  tag?: PricingTag;
  displayPrice: number;
  trialDays: number;
  isCardHorizontal?: boolean;
}

const PricingCard = ({
  pricing,
  selected,
  title,
  features,
  discount,
  description,
  subscription,
  plan,
  tag,
  displayPrice,
  trialDays,
  isCardHorizontal,
}: IProps) => {
  const navigate = useNavigate();
  const isSmallViewWidth = useMediaQuery({ maxWidth: 600 });
  const dashboard = useSelector(dashboardDataSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const [contentModalConfirm, setContentModalConfirm] = useState<ReactNode | null>();
  const [shouldReFetch, setShouldReFetch] = useState(false);
  const [chargePrice, { isFetching: isLoadingCharge }] = apiCaller.useLazyChargePriceQuery();
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });
  const [getGeneralSetting, { isFetching: isLoadingGetSetting }] = apiCaller.useLazyGeneralSettingsQuery();
  const [getDataSummary] = apiCaller.useLazyGetDataSummaryQuery();

  useEffect(() => {
    window.onfocus = () => {
      if (!isLoadingGetSetting && shouldReFetch) {
        getGeneralSetting();
        getDataSummary({
          startTime: dateToTimeStamp(dashboard.startDate),
          endTime: dateToTimeStamp(dashboard.endDate),
        });
        setShouldReFetch(false);
      }
    };
  }, [isLoadingGetSetting, shouldReFetch, getGeneralSetting, getDataSummary, dashboard]);

  const handleChargePlan = async () => {
    try {
      const res = await chargePrice({
        plan,
        subscription,
      }).unwrap();
      if (res?.data?.url) window.open(res.data.url, '_blank');
      else getGeneralSetting();
      getDataSummary({
        startTime: dateToTimeStamp(dashboard.startDate),
        endTime: dateToTimeStamp(dashboard.endDate),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmSelected = () => {
    handleChargePlan();
    handleCloseModalConfirm();
    setShouldReFetch(true);
  };

  const handleCloseModalConfirm = () => {
    setIsOpenModalConfirm(false);
  };

  const handleOpenModalConfirm = (selectedPlan: PricingPlan.Free | PricingPlan.Pro | PricingPlan.Basic) => {
    setIsOpenModalConfirm(true);
    let limitedArray: string[] = [];
    switch (selectedPlan) {
      case PricingPlan.Free:
        limitedArray = wanningDowngrade.toFree;
        break;
      case PricingPlan.Basic:
        limitedArray = wanningDowngrade.toBasic;
        break;
      case PricingPlan.Pro:
        limitedArray = wanningDowngrade.toPro;
        break;
      default:
        return;
    }
    const ContentModal = (
      <div>
        <Text as="p">
          Downgrading to <b>{capitalizeFirstLetter(selectedPlan)} Plan</b> will limit you to:
        </Text>
        <List type="bullet">
          {limitedArray?.map((feature, index) => (
            <List.Item key={index}>{feature}</List.Item>
          ))}
        </List>
        <div className="mt-8 box-banner-container">
          <Banner tone="critical">
            <p>
              <b>Important:</b>{' '}
              {`Any existing data (chat widgets, tracked visitors, agents, etc.) that are beyond ${capitalizeFirstLetter(
                selectedPlan,
              )} Plan limits
            will be permanently deleted 3 days after downgrading. You can safely restore the data by re-upgrading to a suitable
            plan within 3 days.`}
            </p>
            <div className="mt-8" style={{ marginLeft: -2 }}>
              <Button onClick={() => navigate(PATH.WIDGETS)}>Review settings</Button>
            </div>
          </Banner>
        </div>
        <p className="mt-8">
          Please{' '}
          <span
            onClick={openCrisp}
            style={{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--p-color-text-emphasis)' }}
          >
            contact support
          </span>{' '}
          if you have additional questions
        </p>
      </div>
    );
    setContentModalConfirm(ContentModal);
  };

  const handleSelectPlan = () => {
    if (
      (plan === PricingPlan.Free && dataSetting?.data?.plan === PricingPlan.Premium) ||
      (plan === PricingPlan.Free && dataSetting?.data?.plan === PricingPlan.Pro) ||
      (plan === PricingPlan.Free && dataSetting?.data?.plan === PricingPlan.Basic) ||
      (plan === PricingPlan.Basic && dataSetting?.data?.plan === PricingPlan.Pro) ||
      (plan === PricingPlan.Basic && dataSetting?.data?.plan === PricingPlan.Premium) ||
      (plan === PricingPlan.Pro && dataSetting?.data?.plan === PricingPlan.Premium)
    ) {
      //downgrade case
      handleOpenModalConfirm(plan);
    } else {
      //upgrage case
      handleConfirmSelected();
    }
  };

  return (
    <PricingCardStyled isCardHorizontal={isCardHorizontal} isSmallViewWidth={isSmallViewWidth}>
      <Card>
        {isCardHorizontal ? (
          <div>
            <Image source={images.omegachat_free} alt={title} height={isSmallViewWidth ? 280 : '100%'} width={'100%'} />
          </div>
        ) : null}
        <div className="w-full wrap-pricing-content">
          {isCardHorizontal ? (
            <strong>{title}</strong>
          ) : (
            <div className={`card-top ${isCardHorizontal ? '' : 'mb-16'}`}>
              <div className="d-flex flex-column">
                <div className="d-flex gap-10">
                  <Text fontWeight="medium" as="h2">
                    {title}
                  </Text>
                  {tag ? <Badge tone="success">{tag}</Badge> : null}
                </div>
                <div className="mt-8">
                  <Text as="p">{description}</Text>
                </div>
                <div className="mt-8">
                  <Text as="h3" fontWeight="medium">
                    {displayPrice === 0 ? 'Free' : `$${displayPrice}`}
                  </Text>
                </div>
                <div className="mt-8">
                  <Text as="p" fontWeight="medium">
                    {pricing === 0 ? 'Forever' : '/month'}
                  </Text>
                </div>
                <div className="d-flex mt-8">
                  {discount ? (
                    <>
                      <p className="mr-4">{discount}% OFF</p>
                      <Text as="p" tone="critical" textDecorationLine="line-through">
                        ${pricing}
                      </Text>
                    </>
                  ) : (
                    <div style={{ height: subscription === Subscription.Annualy ? 20 : 'auto' }}></div>
                  )}
                </div>
                <ChargeActionBtn
                  className="w-full text-center mt-16 mb-8"
                  trialDays={trialDays}
                  handleSelectPlan={handleSelectPlan}
                  isLoading={isLoadingCharge || isLoadingGetSetting}
                  plan={plan}
                  selected={!!selected}
                />
              </div>
            </div>
          )}
          <div className={`card-content ${isCardHorizontal ? '' : 'mt-16'}`}>
            {features.map((feature, index) => (
              <React.Fragment key={index}>
                <div className="mt-8 d-flex">
                  <Icon source={feature.icon ? feature.icon : CheckIcon} tone={feature.icon ? 'base' : 'success'} />
                  <Text fontWeight={feature.icon ? 'bold' : 'regular'} as="p">
                    {feature.description}
                  </Text>
                  {feature.tooltip ? (
                    <Tooltip content={feature.tooltip}>
                      <Icon source={InfoIcon} />
                    </Tooltip>
                  ) : null}
                </div>
                {index < features.length - 1 ? (
                  <>
                    <div className="mb-8"></div>
                    <Divider />
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </div>
          {isCardHorizontal ? (
            <ChargeActionBtn
              className="w-fit mt-16"
              trialDays={trialDays}
              handleSelectPlan={handleSelectPlan}
              isLoading={isLoadingCharge || isLoadingGetSetting}
              plan={plan}
              selected={!!selected}
            />
          ) : null}
        </div>
      </Card>

      <ModalConfirm
        isOpen={isOpenModalConfirm}
        onClose={handleCloseModalConfirm}
        isLoading={false}
        title="Review plan downgrade and data storage policy"
        sectionText=""
        sectionContent={<>{contentModalConfirm}</>}
        primaryBtn={
          <Button variant="primary" onClick={handleCloseModalConfirm}>
            Stay on current plan
          </Button>
        }
        secondaryBtn={<Button onClick={handleConfirmSelected}>Confirm downgrade</Button>}
      />
    </PricingCardStyled>
  );
};

export default PricingCard;
