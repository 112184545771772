import CreateWidgetBtn from '@/components/CreateWidgetBtn';
import DatePicker from '@/components/DatePicker';
import LimitedBanner from '@/components/LimitedBanner';
import Layout from '@/components/layout';
import { PricingPlan } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import widgetsSlice, { titleBtnDatePickerSelector, widgetsDataSelector } from '@/redux/slice/widgets.slice';
import { Card } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from './component/Table';
import { WidgetsStyled } from './styled';
import { capitalizeFirstLetter } from '@/helpers/string';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const Widgets = () => {
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);
  const widgetsData = useSelector(widgetsDataSelector);
  const titleBtnDatePicker = useSelector(titleBtnDatePickerSelector);
  const { data } = apiCaller.useListGroupButtonQuery(
    {
      endTime: widgetsData.endDate.getTime(),
      startTime: widgetsData.startDate.getTime(),
      page: widgetsData.page,
      perPage: widgetsData.perPage,
      sortDirection: widgetsData.sortDirection,
    },
    { skip: isSkip },
  );
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });

  return (
    <Layout title="Widgets">
      <WidgetsStyled>
        <LimitedBanner
          title={`${capitalizeFirstLetter(dataSetting?.data?.plan || PricingPlan.Free)} Plan Limit`}
          content={
            <>
              <p>
                {`You have used ${Math.min(1, data?.data?.filter((group) => group.isActive)?.length || 0)}/1 widget.`}
                {data?.data?.filter((group) => group.isActive)?.length ? (
                  <b className="ml-4">({data?.data?.find((group) => group.isActive)?.name || ''})</b>
                ) : null}
              </p>
              <p>Upgrade for Unlimited widgets, visitor analytics, page target, and more.</p>
            </>
          }
        />
        <div className="widgets-controller mb-8">
          <DatePicker
            titleButton={titleBtnDatePicker}
            setTitleButton={(value) => dispatch(widgetsSlice.actions.handleTitleBtnDatePicker(value))}
            startDate={widgetsData.startDate}
            endDate={widgetsData.endDate}
            onSave={(start, end) =>
              dispatch(
                widgetsSlice.actions.handleData({
                  ...widgetsData,
                  startDate: start,
                  endDate: end,
                }),
              )
            }
          />
          {!!data?.data.length ? (
            <CreateWidgetBtn
              buttonTitle="New widget"
              disableCreate={
                [PricingPlan.Free, PricingPlan.Basic].includes(dataSetting?.data?.plan || PricingPlan.Free) &&
                data?.data.length >= 1
              }
            />
          ) : null}
        </div>

        <Card padding={'400'}>
          <Table hasAction endTime={widgetsData.endDate.getTime()} startTime={widgetsData.startDate.getTime()} />
        </Card>
      </WidgetsStyled>
    </Layout>
  );
};
export default memo(Widgets);
