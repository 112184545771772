import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { useSelector } from 'react-redux';

export const usePages = () => {
  const isSkip = useSelector(isSkipApiSelector);
  const { data } = apiCaller.useGetPagesQuery(undefined, { skip: isSkip });
  return {
    pageOptions:
      data?.data.map((item) => {
        return {
          value: item.handle,
          label: item.title,
        };
      }) || [],
  };
};
