import { Button, Card, Icon, Text, Tooltip } from '@shopify/polaris';
import { ThingsToDoNextStyled } from './styled';
import BoldText from '@/components/BoldText';
import { useEffect, useState } from 'react';
import { XIcon } from '@shopify/polaris-icons';
import Step from '../Step';
import { config } from '@/config';
import { images } from '@/asset';
import CreateWidgetBtn from '@/components/CreateWidgetBtn';
import { apiCaller } from '@/redux/query';
import { useDispatch, useSelector } from 'react-redux';
import thinksToDoSlice, { isDoneAllSteps } from '@/redux/slice/thinksToDo.slice';
import { PricingPlan } from '@/constants/enum';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

interface Props {
  numberGroupButtons: number;
}

const ThingsToDoNext = ({ numberGroupButtons }: Props) => {
  const isSkip = useSelector(isSkipApiSelector);
  const { data } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery();
  const [progress, setProgress] = useState(-1);
  const [currentStep, setCurrentStep] = useState(-1);
  const [isEnabledEmbedded, setIsEnabledEmbbed] = useState(false);
  const [isFetchingEmbedded, setIsFetchingEmbedded] = useState(true);
  const [getEmbeddedAppStatus] = apiCaller.useLazyGetEmbeddedAppStatusQuery();
  const isDoneAll = useSelector(isDoneAllSteps);
  const dispatch = useDispatch();

  const steps = [
    {
      id: 0,
      name: 'Enable the app embed on your theme',
      component: (
        <>
          <Text as="p">
            To ensure the Omega Chat works with your theme, please make sure to turn on the app in the Theme Editor.
          </Text>
          <img src={images.omegaChatEdit} alt="open theme" className="mt-8 mb-16" style={{ width: '100%' }} />
          <Button
            onClick={() => {
              window.open(
                `https://${config.shop}/admin/themes/current/editor?context=apps&activateAppId=${process.env.REACT_APP_THEME_APP_EXTENSION_ID}/omg-facebook-chat`,
              );
            }}
          >
            Turn on
          </Button>
        </>
      ),
    },
    {
      id: 1,
      name: 'Create widget button',
      component: (
        <>
          <div className="mb-8">
            <Text as="p">
              Create a widget for your store to increase sales conversion rates. We offer integration with{' '}
              <b>Facebook Messenger, WhatsApp,</b> and <b>over 20 other channels</b>. You can always add more later.
            </Text>
          </div>
          {[PricingPlan.Free, PricingPlan.Basic]?.includes(dataSetting?.data?.plan || PricingPlan.Free) &&
          dataSetting?.data &&
          dataSetting?.data?.countGroupButtons > 0 ? null : (
            <CreateWidgetBtn buttonTitle="Create a widget" />
          )}
        </>
      ),
    },
    {
      id: 2,
      name: 'See the widget appear on your store',
      component: (
        <>
          <div className="mb-8">
            <Text as="p">
              After completing the setup, check to see if the chat widget is working properly on your store as expected.
            </Text>
          </div>
          <Button
            onClick={() => {
              window.open(`https://${config.shop}`, '_blank');
              setProgress(2);
              setCurrentStep(2);
              dispatch(thinksToDoSlice.actions.handleVisibleThinksToDo({ key: 'isDoneAllSteps', value: true }));
            }}
          >
            Check now
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (isFetchingEmbedded || !data) return;

    if (isEnabledEmbedded) {
      if (isDoneAll && data) {
        const isEnableAtLeastOneButtonGroup = data.data.countActiveGroupButtons > 0;
        !isEnableAtLeastOneButtonGroup
          ? dispatch(thinksToDoSlice.actions.handleVisibleThinksToDo({ key: 'isDoneAllSteps', value: false }))
          : setProgress(steps.length - 1);
        return;
      }
      const isEnableAtLeastOneButtonGroup = data.data.countActiveGroupButtons > 0;
      isEnableAtLeastOneButtonGroup ? setProgress(1) : setProgress(0);
    } else {
      setCurrentStep(0);
    }
  }, [numberGroupButtons, isEnabledEmbedded, isDoneAll, steps.length, dispatch, data, isFetchingEmbedded]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFetchingEmbedded(true);
      getEmbeddedAppStatus()
        .then((res) => {
          if (res.data?.data) {
            setIsEnabledEmbbed(res.data.data.isAppEnabled);
          }
        })
        .finally(() => {
          setIsFetchingEmbedded(false);
        });
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentStep === -1 && progress !== -1 && progress !== steps.length - 1) {
      setCurrentStep(progress + 1);
    }
  }, [progress, currentStep, steps.length]);

  return (
    <ThingsToDoNextStyled>
      <Card>
        <div className="header">
          <BoldText>Things to do next</BoldText>
          <Tooltip content="Dismiss">
            <div
              onClick={() => {
                dispatch(thinksToDoSlice.actions.handleVisibleThinksToDo({ key: 'isVisibleThinkTodos', value: false }));
              }}
            >
              <Icon source={XIcon} tone="base" />
            </div>
          </Tooltip>
        </div>
        <div className="progress">
          <div className="current-step">{`${progress + 1} / ${steps.length} completed`}</div>
          <div className="home-process-container">
            <div className="home-process" style={{ maxWidth: `${(progress + 1) * (100 / 3)}%`, transition: 'all .5s' }} />
          </div>
        </div>
        <div className="mt-16">
          {steps.map((step) => (
            <Step
              key={step.id}
              open={currentStep === step.id}
              isDone={progress >= step.id}
              setOpen={setCurrentStep}
              dataStep={step}
              isDisabled={progress < step.id - 1}
              isFetching={isFetchingEmbedded && step.id === 0}
            />
          ))}
        </div>
      </Card>
    </ThingsToDoNextStyled>
  );
};

export default ThingsToDoNext;
