import { Button, Icon, Link, Modal, TextField, Tooltip } from '@shopify/polaris';

import CustomCard from '@/components/customCard';
import { CustomLinkIcon } from '@/components/Icons/CustomLink';
import RegularText from '@/components/RegularText';
import UpgradeText from '@/components/UpgradeText';
import WarningBanner from '@/components/WarningBanner';
import { BREAKPOINT, Device, ICon, PricingPlan, Subscription, WidgetPlatform } from '@/constants/enum';
import { useUserPlan } from '@/hooks/useUserPlan';
import CardGroupBtn from '@/pages/NewWidget/StepOne/components/CardGroupBtn';
import { apiCaller } from '@/redux/query';
import toastSlice from '@/redux/slice/toast.slice';
import widgetSlice, {
  channelsSeletedSelector,
  customizeSelected,
  dataSelector,
  errorSelector,
  initGroupButton,
} from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { InfoIcon, PlusIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { listIconDefault } from '../listIconDefault';
import { StepOneStyled } from './styled';
import { PATH } from '@/constants/path';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const StepOne = () => {
  const isSkip = useSelector(isSkipApiSelector);
  const error = useSelector(errorSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });
  const navigate = useNavigate();
  const { isLimitedFormAnswer } = useUserPlan();
  const location = useLocation();
  const { state }: { state: { defaultWidget: ICon } } = useLocation();
  const [modalConfirmRemove, setModalConfirmRemove] = useState<{ status: boolean; icon?: ICon; customlinkId?: number }>({
    status: false,
  });
  const [isInitAddFrom, setIsInitAddFrom] = useState(true);
  const dispatch = useDispatch();
  const channelsSelected = useSelector(channelsSeletedSelector);
  const customize = useSelector(customizeSelected);
  const data = useSelector(dataSelector);

  const handleChangeWidgetName = (value: string) => {
    dispatch(
      widgetSlice.actions.handleData({
        ...data,
        name: value,
      }),
    );
  };

  const handleIconClick = useCallback(
    (key: ICon, value: IParamsApi.IChannelButton, customLinkId?: number, isUseForDefault?: boolean) => {
      const newChannels = [...channelsSelected];
      const index = channelsSelected.findIndex((item) => item.channel === key);
      if (index === -1) {
        newChannels.push(value);
        if (error.status && error.field === 'buttons') {
          dispatch(
            widgetSlice.actions.handleError({
              status: false,
            }),
          );
        }
      } else {
        if (!isUseForDefault) {
          setModalConfirmRemove({
            status: true,
            customlinkId: customLinkId,
            icon: key,
          });
        }
      }
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    },
    [channelsSelected, dispatch, error.field, error.status],
  );

  /**
   * @description: handle add default widget
   */
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (state?.defaultWidget && isInitAddFrom) {
        setIsInitAddFrom(false);
        const formItem = listIconDefault.find((item) => item.keyValue === state?.defaultWidget);
        const isCreate = location.pathname === PATH.NEW_WIDGET.pathname;
        if (isCreate) {
          const newInit = { ...initGroupButton };
          if (formItem?.value) {
            newInit.channelsSelected = [...newInit.channelsSelected, formItem.value as IParamsApi.IChannelButton];
            dispatch(widgetSlice.actions.handleData(newInit));
          }
        } else {
          const newChannels = [...channelsSelected];
          const index = channelsSelected.findIndex((item) => item.channel === formItem?.keyValue);
          if (index === -1 && formItem) {
            newChannels.push(formItem.value);
            dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
          }
        }
      }
    }, 0);
    return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(channelsSelected), dispatch, location.pathname, state?.defaultWidget, isInitAddFrom]);

  const handleRemoveCustomChannel = (customId?: number) => {
    const newChannels = [...channelsSelected];
    const index = channelsSelected.findIndex((item) => item.customLinkId === customId);
    newChannels.splice(index, 1);
    dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
  };

  const handleRemove = () => {
    if (modalConfirmRemove.customlinkId) {
      handleRemoveCustomChannel(modalConfirmRemove.customlinkId);
    } else {
      const newChannels = [...channelsSelected];
      const index = channelsSelected.findIndex((item) => item.channel === modalConfirmRemove.icon);
      if (index !== -1) {
        newChannels.splice(index, 1);
        dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
      }
    }
    const index = error.icon?.findIndex((icon) => icon === modalConfirmRemove.icon);
    if (index !== -1) {
      const newIcons = error.icon?.filter((icon) => icon !== modalConfirmRemove.icon);
      dispatch(
        widgetSlice.actions.handleError({
          ...error,
          icon: newIcons,
        }),
      );
    }
    dispatch(
      toastSlice.actions.handleToast({
        content: 'Deleted',
        error: false,
        isOpen: true,
      }),
    );
    setModalConfirmRemove({ status: false });
  };

  const isDisabledSelect = useMemo(() => {
    const isFreePlan = dataSetting?.data?.plan === PricingPlan.Free;
    return isFreePlan && channelsSelected.length >= 3;
  }, [channelsSelected.length, dataSetting?.data?.plan]);

  const isLimited = useMemo(() => {
    return dataSetting?.data?.plan === PricingPlan.Free || dataSetting?.data?.plan === PricingPlan.Basic;
  }, [dataSetting?.data?.plan]);

  return (
    <StepOneStyled borderRadius={customize.buttonStyle}>
      <WarningBanner isVisible={isDisabledSelect} hiddenContactSupport>
        <RegularText>
          You can add up to <b>3</b> channel buttons per widget with current plan.{' '}
          <Link
            onClick={() => {
              navigate(PATH.PRICING_PLAN, { state: Subscription.Monthly });
            }}
          >
            <b>Upgrade now</b>
          </Link>{' '}
          to add unlimited channels at once.
        </RegularText>
      </WarningBanner>
      <TextField
        error={error.msg ? 'Name is already existed' : error.field === 'name' ? 'Name is required!' : undefined}
        onBlur={() => {
          if (!data.name) {
            dispatch(
              widgetSlice.actions.handleError({
                ...error,
                field: 'name',
                status: true,
              }),
            );
          }
        }}
        onChange={handleChangeWidgetName}
        value={data.name}
        requiredIndicator
        label="Widget name"
        autoComplete="off"
        autoFocus
      />
      <div className="icon-button-container">
        {/* render list icon default */}
        <CardGroupBtn
          title="Messaging platform"
          listIcon={listIconDefault.filter((item) => item.platform === WidgetPlatform.Messaging)}
          channelsSelected={channelsSelected}
          isLimited={isLimited}
          isDisabledSelect={isDisabledSelect}
          handleIconClick={handleIconClick}
          description="Instant communication channels for real-time interaction with customers via messaging and email."
        />

        <div className="mt-8 w-full">
          <CardGroupBtn
            title="Social media platform"
            listIcon={listIconDefault.filter((item) => item.platform === WidgetPlatform.SocialMedia)}
            channelsSelected={channelsSelected}
            isLimited={isLimited}
            isDisabledSelect={isDisabledSelect}
            handleIconClick={handleIconClick}
            description="Connect and engage with customers through popular social media networks."
          />
        </div>

        <div className="mt-8 w-full">
          <CardGroupBtn
            title="Collaboration platform"
            listIcon={listIconDefault.filter((item) => item.platform === WidgetPlatform.Collaboration)}
            channelsSelected={channelsSelected}
            isLimited={isLimited}
            isDisabledSelect={isDisabledSelect}
            handleIconClick={handleIconClick}
            description="Invite users to collaborate and communicate within your team via specialized platforms."
          />
        </div>

        <div className="mt-8 w-full">
          <CardGroupBtn
            title="Contact Form"
            listIcon={listIconDefault.filter((item) => item.platform === WidgetPlatform.ContactForm)}
            channelsSelected={channelsSelected}
            isLimited={isLimited}
            isDisabledSelect={isDisabledSelect}
            handleIconClick={handleIconClick}
            description="Collect valuable feedback and customer information through a customizable form."
          >
            {isLimitedFormAnswer ? (
              <div className="mb-8">
                <UpgradeText title="Upgrade to Premium plan" description="to unlock contact form." style={{ marginTop: -8 }} />
              </div>
            ) : null}
          </CardGroupBtn>
        </div>

        <div className="mt-8 w-full">
          <CardGroupBtn
            title="Location"
            listIcon={listIconDefault.filter((item) => item.platform === WidgetPlatform.Location)}
            channelsSelected={channelsSelected}
            isLimited={isLimited}
            isDisabledSelect={isDisabledSelect}
            handleIconClick={handleIconClick}
            description="Help customers find your business or navigate to specific locations with map and direction services."
          />
        </div>

        <div className="mt-8 w-full">
          <CardGroupBtn
            title="Navigation"
            listIcon={listIconDefault.filter((item) => item.platform === WidgetPlatform.Navigation)}
            channelsSelected={channelsSelected}
            isLimited={isLimited}
            isDisabledSelect={isDisabledSelect}
            handleIconClick={handleIconClick}
            description="Quickly direct customers to important sections of your website, like the homepage or cart."
          />
        </div>

        <div className="mt-8 w-full">
          <CustomCard
            title={
              <div className="d-flex align-items-center">
                <b>Custom</b>
                <Tooltip content="Personalize your chat widget to meet your unique business needs and give your customers more ways to connect">
                  <Icon source={InfoIcon} tone="subdued" />
                </Tooltip>
              </div>
            }
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <div
              className={`channel-container-select${isDisabledSelect ? ' disabled-select' : ''}`}
              onClick={() => {
                if (isDisabledSelect) return;
                const newChannels = [...channelsSelected];
                const listCustomLinkId = newChannels
                  .filter((item) => typeof item.customLinkId === 'number')
                  .map((item) => item.customLinkId || 1);

                newChannels.push({
                  customLinkId: listCustomLinkId.length > 0 ? Math.max(...listCustomLinkId) + 1 : 1,
                  channel: ICon.CustomLink,
                  value: '',
                  hoverText: 'CustomLink',
                  showOn: [Device.Pc, Device.Mobile],
                  backgroundColor: '#1E88E5',
                  iconColor: '#ffffff',
                  iconId: 1,
                });
                dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
              }}
              style={{
                cursor: isDisabledSelect ? 'not-allowed' : 'pointer',
              }}
            >
              <div className={`add-custom-container icon-container ${isDisabledSelect ? 'disabled-select' : ''}`}>
                <Icon source={PlusIcon} />
              </div>

              <div className="text-container">Add custom</div>
            </div>

            {/* Render customlink selected */}
            {channelsSelected
              .filter((channel) => channel.channel === ICon.CustomLink)
              .map((item, index) => {
                return (
                  <div
                    className={`channel-container-select selected`}
                    key={`${index}-custom-channel`}
                    onClick={() =>
                      setModalConfirmRemove({
                        status: true,
                        customlinkId: item.customLinkId,
                        icon: ICon.CustomLink,
                      })
                    }
                  >
                    <div className="icon-container">
                      <CustomLinkIcon channel={item} />
                    </div>

                    <div className="text-container">Custom {index + 1}</div>
                  </div>
                );
              })}
            {/* Render button add icon */}
          </CustomCard>
        </div>
      </div>

      {/* Modal remove channel */}

      <Modal
        open={modalConfirmRemove.status}
        title="Are you sure?"
        onClose={() => setModalConfirmRemove({ status: false })}
        sectioned
        footer={
          <div className="content-right" style={{ width: isMobile ? 'calc(100vw - 3rem)' : '588px' }}>
            <Button onClick={() => setModalConfirmRemove({ status: false })}>No</Button>
            <div className="ml-8">
              <Button tone="critical" variant="primary" onClick={handleRemove}>
                Yes
              </Button>
            </div>
          </div>
        }
      >
        <RegularText>
          Do you want to remove the <b>{listIconDefault.find((item) => item.keyValue === modalConfirmRemove.icon)?.label}</b>{' '}
          button from the widget?
        </RegularText>
      </Modal>
    </StepOneStyled>
  );
};

export default StepOne;
