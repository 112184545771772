import { PricingPlan } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { Button } from '@shopify/polaris';
import { useSelector } from 'react-redux';

interface IProps {
  plan: PricingPlan;
  selected: boolean;
  handleSelectPlan: () => void;
  isLoading: boolean;
  className?: string;
  trialDays: number | string;
}

export default function ChargeActionBtn({ handleSelectPlan, selected, plan, isLoading, className, trialDays }: IProps) {
  const isSkip = useSelector(isSkipApiSelector);
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });
  return (
    <div className={className}>
      {selected || plan === PricingPlan.Free ? (
        <Button
          fullWidth
          disabled={selected || dataSetting?.data?.plan === PricingPlan.Free}
          onClick={handleSelectPlan}
          loading={isLoading}
        >
          {selected || dataSetting?.data?.plan === PricingPlan.Free ? 'Current plan' : 'Select plan'}
        </Button>
      ) : (
        <Button fullWidth variant="primary" onClick={handleSelectPlan} loading={isLoading}>
          {`Start your ${trialDays}-day free trial`}
        </Button>
      )}
    </div>
  );
}
