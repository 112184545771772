import { listDefaultAvatarAgents } from '@/components/ChannelsButton/Component/ModalAgent';
import { workingTimeFilter } from '@/components/Icons/Window/AgentWindow/helper/agent';
import UpgradeText from '@/components/UpgradeText';
import { PricingPlan } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { triggerSelector } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { Badge, Button, Tooltip } from '@shopify/polaris';
import { TeamIcon, XIcon } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';

interface IProps {
  showAddAgent?: boolean;
  channel: {
    customChannel: IParamsApi.IChannelButton | undefined;
    channel: IParamsApi.IChannelButton | undefined;
  };
  setIsOpenModalAddAgent: React.Dispatch<React.SetStateAction<boolean>>;
  setAgentSelected: React.Dispatch<React.SetStateAction<IParamsApi.IAgent | undefined>>;
  setIsOpenModalRemoveAgent: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddAgent = ({ showAddAgent, channel, setIsOpenModalAddAgent, setAgentSelected, setIsOpenModalRemoveAgent }: IProps) => {
  const trigger = useSelector(triggerSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });

  const handleEditAgent = (agent: IParamsApi.IAgent) => {
    setAgentSelected(agent);
    setIsOpenModalAddAgent(true);
  };

  return showAddAgent ? (
    <div className="d-flex mt-16 flex-center wrap gap-10 wrap-add-agent">
      {dataSetting?.data?.plan === PricingPlan.Premium ? (
        <>
          <Button onClick={() => setIsOpenModalAddAgent(true)} icon={TeamIcon} fullWidth>
            Add agent
          </Button>
          {channel.channel?.agents?.map((agent) => {
            const avatarUrl = agent.customAvatar
              ? agent.customAvatar
              : listDefaultAvatarAgents.find((avatar) => avatar.id === agent.avatarId)?.url;

            const isOnWorkingTime = workingTimeFilter(channel.channel?.agents || [], trigger.timezone)?.find((agentIsWorking) =>
              agentIsWorking.id
                ? agentIsWorking.id === agent.id
                : agentIsWorking.customId
                ? agentIsWorking.customId === agent.customId
                : false,
            );

            const TooltipContent = (
              <>
                <div>Name: {agent.name}</div>
                <div>Role: {agent.role}</div>
                <div>Jobs: {agent.jobs}</div>
                <div className="d-flex mb-4">
                  Status:
                  <div className="ml-8">
                    <Badge tone={isOnWorkingTime ? 'success' : undefined}>{isOnWorkingTime ? 'Active' : 'Inactive'}</Badge>
                  </div>
                </div>
              </>
            );
            return (
              <div className="ml-8 wrap-avatar" key={agent.customId || agent.id}>
                <div className="remove-btn">
                  <Button
                    onClick={() => {
                      setAgentSelected(agent);
                      setIsOpenModalRemoveAgent(true);
                    }}
                    icon={XIcon}
                    variant="plain"
                  ></Button>
                </div>
                <Tooltip content={TooltipContent}>
                  <div
                    className="wrap-img"
                    onClick={() => {
                      handleEditAgent(agent);
                    }}
                  >
                    <img
                      src={avatarUrl}
                      alt="avatar"
                      width={35}
                      height={35}
                      style={{ filter: isOnWorkingTime ? 'grayscale(0)' : 'grayscale(1)' }}
                    />
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className="w-full mt-4">
            <Tooltip content="Available on Premium plan">
              <Button disabled icon={TeamIcon} fullWidth>
                Add agent
              </Button>
            </Tooltip>
          </div>
          <UpgradeText title="Upgrade to Premium plan" description="to add unlimited agents." style={{ marginTop: -8 }} />
        </>
      )}
    </div>
  ) : null;
};

export default AddAgent;
