import BoldSmallText from '@/components/BoldSmallText';
import { CustomLinkIcon } from '@/components/Icons/CustomLink';
import useVariant from '@/components/Icons/Variant';
import { ModalSelectWidgetStyled } from '@/components/ModalSelectWidget/styled';
import RegularText from '@/components/RegularText';
import { Device, ICon, IconIds } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { listIconPreview } from '@/pages/NewWidget/listIconDefault';
import { IResponseApi } from '@/types/apis/response';
import { Icon, Modal, SkeletonBodyText } from '@shopify/polaris';
import { ChevronRightIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data?: IResponseApi.IListButtonGroup;
  isLoading: boolean;
}

interface WidgetsProps {
  data: IResponseApi.IButton[];
  name: string;
  id: number;
}

const Widgets = ({ data, name, id }: WidgetsProps) => {
  const variant = useVariant();
  const navigate = useNavigate();
  const isOnMobile = useMediaQuery({ maxWidth: 320 });

  const MAX_ICON_PREVIEW = isOnMobile ? 3 : 5;
  const filterIcon = useCallback(
    (channel: IResponseApi.IButton) => {
      const showOn = [];
      if (channel.isDesktop) showOn.push(Device.Pc);
      if (channel.isMobile) showOn.push(Device.Mobile);
      const image = channel.customImage;
      if (channel.iconId === IconIds.Custom) {
        return <img className="preview-icon-item" src={image} width={30} height={30} alt={channel.hoverText} key={channel.id} />;
      }
      if (channel.channel === ICon.CustomLink)
        return (
          <CustomLinkIcon
            channel={{
              ...channel,
              showOn,
              customImage: {
                url: channel.customImage,
              },
            }}
          />
        );
      const result = listIconPreview.find((item) => item.keyValue === channel.channel);
      if (result) {
        if (result.variant) {
          return variant
            .find((item) => item.id === channel.channel)
            ?.variant(40, channel.backgroundColor, channel.iconColor)
            .find((item) => item.id === channel.iconId)?.element;
        } else {
          return result.icon(channel.backgroundColor, channel.iconColor);
        }
      }
    },
    [variant],
  );
  return (
    <div
      className="d-flex justify-between align-center widget-item"
      onClick={() => {
        navigate(
          { pathname: `/widgets/${id}`, search: PATH.EDIT_WIDGET.search },
          { state: { defaultWidget: ICon.ContactForm, isFromFormAction: true } },
        );
      }}
    >
      <div className="d-flex align-center justify-between" style={{ width: 'calc(100% - 30px)' }}>
        <div className="widget-name">
          <BoldSmallText>{name}</BoldSmallText>
        </div>
        <div className="d-flex preview-icon-container" style={{ minWidth: isOnMobile ? '105px' : '165px' }}>
          {data.map((item, index) => {
            if (index < MAX_ICON_PREVIEW) {
              return <div key={item.id}>{filterIcon(item)}</div>;
            }
            return null;
          })}
          {data.length > MAX_ICON_PREVIEW ? (
            <div className="preview-icon-item preview-icon-plus">
              <RegularText>+{data.length - MAX_ICON_PREVIEW}</RegularText>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <Icon source={ChevronRightIcon} />
      </div>
    </div>
  );
};

const ModalSelectWidget = ({ data, isLoading, isOpen, setIsOpen }: Props) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={isOpen}
      title="Choose your Widget"
      onClose={() => setIsOpen(false)}
      sectioned
      primaryAction={{
        content: 'Create New Widget',
        onAction: () => {
          navigate(PATH.NEW_WIDGET, { state: { defaultWidget: ICon.ContactForm, isFromFormAction: true } });
        },
        icon: PlusCircleIcon,
      }}
    >
      {isLoading ? (
        <SkeletonBodyText lines={16} />
      ) : (
        <ModalSelectWidgetStyled>
          {data?.data.map((item) => (
            <Widgets id={item.id} name={item?.name || ''} data={item?.buttons} key={item.id} />
          ))}
        </ModalSelectWidgetStyled>
      )}
    </Modal>
  );
};

export default ModalSelectWidget;
