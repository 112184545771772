import { Button, InlineGrid, TextField } from '@shopify/polaris';

import ColorPicker from '@/components/ColorPicker';
import CustomWidgetIcon from '@/components/Icons/CustomWidgetIcon';
import { WidgetIconFour } from '@/components/Icons/WidgetIconFour';
import { WidgetIconOne } from '@/components/Icons/WidgetIconOne';
import { WidgetIconThree } from '@/components/Icons/WidgetIconThree';
import { WidgetIconTwo } from '@/components/Icons/WidgetIconTwo';
import RegularText from '@/components/RegularText';
import Switch from '@/components/Switch';
import UploadImageWidget from '@/components/UploadImage/UploadImageWidget';
import { DefaultState, WidgetIcon } from '@/constants/enum';
import { useWidgetIcon } from '@/hooks/useWidgetIcon';
import widgetSlice, { channelsSeletedSelector, customizeSelected, errorSelector } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { ExchangeIcon, SettingsIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { useChannelsButton } from '../../../hooks/useChannelsButton';
import { StepTwoStyled } from './styled';
import { capitalizeFirstLetter } from '@/helpers/string';
import BaseCollapsible from '@/components/BaseCollapsible/BaseCollapsible';
const widgetIcon: { key: WidgetIcon; icon: JSX.Element }[] = [
  { key: WidgetIcon.WidgetIconChatBase, icon: <WidgetIconOne /> },
  { key: WidgetIcon.WidgetIconChatSmile, icon: <WidgetIconTwo /> },
  { key: WidgetIcon.WidgetIconChatBubble, icon: <WidgetIconThree /> },
  { key: WidgetIcon.WidgetIconChatDb, icon: <WidgetIconFour /> },
];

interface IChannelSort extends Omit<IParamsApi.IChannelButton, 'id'> {
  id: number | string;
  chosen?: boolean;
  selected?: boolean;
}
const StepTwo = () => {
  const dispatch = useDispatch();
  const channelsSelected = useSelector(channelsSeletedSelector);
  const customize = useSelector(customizeSelected);
  const channels = useChannelsButton();
  const widget = useWidgetIcon();
  const error = useSelector(errorSelector);
  const [isOpenSettings, setIsOpenSettings] = useState(false);

  const handleChangeCustomize = useCallback(
    (key: keyof typeof customize) => (value: any) => {
      dispatch(
        widgetSlice.actions.handleCustomize({
          ...customize,
          [key]: value,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customize],
  );
  const handleSort = (state: IChannelSort[]) => {
    const newState = state.reverse().map(({ chosen, selected, ...item }) => ({
      ...item,
      id: typeof item.id === 'string' ? undefined : item.id,
    }));
    dispatch(widgetSlice.actions.handleChannelsSelected(newState));
  };
  const handleReverse = () => {
    const newChannelSelected = [...channelsSelected];
    newChannelSelected.reverse();
    dispatch(widgetSlice.actions.handleChannelsSelected(newChannelSelected));
  };
  useEffect(() => {
    if (error.status && error.icon && error.icon.length) {
      const icon = error.icon[0];
      const element = document.getElementById(`channel-selected-${icon}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.status]);
  return (
    <StepTwoStyled borderRadius={customize.buttonStyle}>
      <div className="mt-8">
        <Button onClick={handleReverse} icon={ExchangeIcon}>
          Reverse
        </Button>
      </div>
      <div className="mt-8">
        <ReactSortable
          animation={200}
          list={[...channelsSelected].reverse().map((item, index) => ({ ...item, id: item.id || `new${index}` }))}
          setList={handleSort}
          handle=".drag-icon"
        >
          {[...channelsSelected].reverse().map((channel) => (
            <div className="selected-icon-container" key={`${channel.channel} ${channel.customLinkId || 'channel'} selected`}>
              {channel.customLinkId
                ? channels.customLink.icon(channel)
                : channels.listButton.find((item) => item.label === channel.channel)?.icon}
            </div>
          ))}
        </ReactSortable>

        {channelsSelected.length > 1 ? (
          <div className="selected-icon-container close-icon-container">
            <div className="input-container">
              <div className="d-flex">
                <div className="widget-icon-header">
                  {customize.groupIcon.url && customize.groupIcon.key === WidgetIcon.CustomWidgetIcon ? (
                    <CustomWidgetIcon />
                  ) : (
                    <div>{widget.groupIcon.find((item) => item.key === customize.groupIcon.key)?.icon}</div>
                  )}
                </div>

                <div className="ml-16 w-100">
                  <TextField
                    placeholder="Close"
                    label="Widget hover"
                    id="widget-input"
                    value={customize.ctaText}
                    onChange={(value) => {
                      dispatch(
                        widgetSlice.actions.handleCustomize({
                          ...customize,
                          ctaText: value,
                        }),
                      );
                    }}
                    autoComplete="off"
                  />
                  <div className="mt-8">
                    <TextField
                      placeholder="Close"
                      label="Close hover"
                      id="close-hover"
                      value={customize.closeHoverText}
                      onChange={(value) => {
                        dispatch(
                          widgetSlice.actions.handleCustomize({
                            ...customize,
                            closeHoverText: value,
                          }),
                        );
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="ml-52 mt-8">
                <div className="d-flex" style={{ alignItems: 'center', marginTop: '4px' }}>
                  <Button pressed={isOpenSettings} onClick={() => setIsOpenSettings(!isOpenSettings)} icon={SettingsIcon}>
                    Settings
                  </Button>
                  <div className="ml-8">
                    <Switch
                      isActive={customize.openAction === DefaultState.Click}
                      onSwitch={(value: boolean) => {
                        dispatch(
                          widgetSlice.actions.handleCustomize({
                            ...customize,
                            openAction: value ? DefaultState.Click : DefaultState.Default,
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
                <BaseCollapsible open={isOpenSettings} id="expand-settings">
                  <div className="mt-8">
                    <RegularText>Widget icon</RegularText>
                    <div className="widget-icon-container">
                      {widgetIcon.map((item, index) => (
                        <div
                          onClick={() => {
                            handleChangeCustomize('groupIcon')({
                              ...customize.groupIcon,
                              key: item.key,
                            });
                          }}
                          key={`widgetIcon-${index}`}
                          className={`${customize.groupIcon.key === item.key ? 'widget-icon widget-icon-active' : 'widget-icon'}`}
                        >
                          {item.icon}
                        </div>
                      ))}
                      <UploadImageWidget />
                    </div>
                    <div className="mt-8">
                      <InlineGrid columns={2}>
                        <ColorPicker
                          title="Widget color"
                          id="widget-color"
                          defaultValue={capitalizeFirstLetter(customize.groupColor)}
                          onBlur={handleChangeCustomize('groupColor')}
                        />
                        <ColorPicker
                          title="Widget icon color"
                          id="widget-icon-color"
                          defaultValue={capitalizeFirstLetter(customize.groupIconColor)}
                          onBlur={handleChangeCustomize('groupIconColor')}
                        />
                      </InlineGrid>
                    </div>
                  </div>
                </BaseCollapsible>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </StepTwoStyled>
  );
};

export default StepTwo;
