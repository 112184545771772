import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

// Define the initial state using that type
const initialState = {
  isVisibleRecommendApps: true,
  isVisibleDateAndTime: true,
  isVisibleAdvancedSettings: true,
  synctrackBanner: true,
  embeddedBanner: true,
  isVisibleBannerHoliday: true,
  isVisibleBannerContactSupport: true,
  isVisibleBannerShiprocket: true,
  isVisibleBannerBlockify: true,
  isVisibleBannerSynctrack: true,
  isVisiblePromotionCard: true,
  isVisibleModalAppQuantityBreak: true,
};

export type BannerSliceKeys = keyof typeof initialState;

export const bannerSlice = createSlice({
  name: 'banner',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleVisibleBanner: (
      state,
      action: PayloadAction<{
        key: BannerSliceKeys;
        value: boolean;
      }>,
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const isVisibleRecommendAppsSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisibleRecommendApps,
);

export const isVisibleDateAndTimeSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisibleDateAndTime,
);

export const isVisibleAdvancedSettingsSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisibleAdvancedSettings,
);

export const synctrackBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.synctrackBanner,
);

export const embeddedBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.embeddedBanner,
);

export const isVisibleBannerHolidaySelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisibleBannerHoliday,
);

export const isVisibleBannerContactSupportSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisibleBannerContactSupport,
);

export const isVisibleBannerShiprocketSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisibleBannerContactSupport,
);

export const isVisibleBannerBlockify = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisibleBannerBlockify,
);

export const isVisibleBannerSynctrack = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisibleBannerSynctrack,
);

export const isVisiblePromotionCardSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisiblePromotionCard,
);

export const isVisibleModalAppQuantityBreakSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.isVisibleModalAppQuantityBreak,
);

export default bannerSlice;
