import styled from 'styled-components';

export const ModalSelectWidgetStyled = styled.div`
  .widget-item {
    border-radius: 12px;
    padding: 10px;
    background-color: #f1f1f1;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      background-color: #ebebeb;
    }
  }
  .Polaris-IndexTable__EmptySearchResultWrapper > .Polaris-Box {
    padding: 0;
  }
  .preview-icon-container {
    svg {
      box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 9px 2px, rgba(0, 0, 0, 0.05) 0px 2px 10px 0px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }
  .widget-name {
    margin-right: 15px;
    h6 {
      max-width: 50px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .preview-icon-container {
    margin-top: 0.5rem;
    & > div {
      margin-left: -0.25rem;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        z-index: 1;
      }
    }
    svg,
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .preview-icon-item {
      margin-left: -0.35rem;
      position: relative;
      &:hover {
        z-index: 1;
      }
      svg {
        border-radius: 50%;
      }
    }
    .preview-icon-plus {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: #a5a5a5 solid 0.5px;
      line-height: 30px !important;
      text-align: center;
      display: flex;
      align-items: center;
      background-color: white;
      cursor: default;
      .Polaris-Text--root {
        width: 25px;
      }
    }
  }
  .widget-name {
    width: calc(100% - 200px);
    h6 {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 320px) {
    .widget-name {
      width: calc(100% - 120px);
    }
  }
`;
