import BoldText from '@/components/BoldText';
import DatePicker from '@/components/DatePicker';
import { PricingPlan, Subscription } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { dateToTimeStamp } from '@/helpers/time';
import { apiCaller } from '@/redux/query';
import { dashboardDataSelector } from '@/redux/slice/dashboard.slice';
import homeSlice, { homeDataSelector, titleBtnDatePickerSelector } from '@/redux/slice/home.slice';
import { Banner, Button, Card, Icon, InlineGrid, SkeletonDisplayText, Text } from '@shopify/polaris';
import { PersonAddIcon, PersonSegmentIcon, ProfileIcon } from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnalyticsStyled } from './styled';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

interface IProps {
  isInHomePage: boolean;
}

const Analytics = ({ isInHomePage }: IProps) => {
  const isSkip = useSelector(isSkipApiSelector);
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const homeData = useSelector(homeDataSelector);
  const dashboard = useSelector(dashboardDataSelector);
  const titleBtnDatePicker = useSelector(titleBtnDatePickerSelector);
  const { data } = apiCaller.useGetDataSummaryQuery(
    {
      startTime: dateToTimeStamp(isInHomePage ? homeData.startDate : dashboard.startDate),
      endTime: dateToTimeStamp(isInHomePage ? homeData.endDate : dashboard.endDate),
    },
    { skip: isSkip },
  );
  const isLoading = false;
  const isLimited = useMemo(() => {
    return [PricingPlan.Free, PricingPlan.Basic].includes(dataSetting?.data?.plan || PricingPlan.Free);
  }, [dataSetting?.data?.plan]);
  const dataChart = useMemo(() => {
    if (data || isLimited) {
      const rate = data?.data?.summary?.visitors ? (data?.data?.summary?.clicks * 100) / data?.data?.summary?.visitors : 0;
      const result = [
        {
          title: 'Visitors',
          value: data?.data?.summary?.visitors || 0,
          icon: ProfileIcon,
        },
        {
          title: 'Unique clicks',
          value: data?.data?.summary?.clicks || 0,
          icon: PersonSegmentIcon,
        },
        {
          title: 'Conversion rate',
          value: `${rate.toFixed(0)}%`,
          icon: PersonAddIcon,
        },
      ];
      return result;
    }
    return [];
  }, [data, isLimited]);
  return (
    <AnalyticsStyled>
      <Card>
        <div className="overview-header">
          <BoldText>Overview</BoldText>
          {isInHomePage && (
            <DatePicker
              titleButton={titleBtnDatePicker}
              setTitleButton={(value: string) => dispatch(homeSlice.actions.handleTitleBtnDatePicker(value))}
              startDate={homeData.startDate}
              endDate={homeData.endDate}
              onSave={(start, end) =>
                dispatch(
                  homeSlice.actions.handleData({
                    endDate: end,
                    startDate: start,
                  }),
                )
              }
              disabled={isLimited}
            />
          )}
        </div>
        {isLimited && isInHomePage ? (
          <div className="mt-8">
            <Banner tone="info">
              <p className="wrap-btn-upgrade">
                <Button onClick={() => navigate(PATH.PRICING_PLAN, { state: Subscription.Monthly })} variant="plain">
                  Upgrade plan
                </Button>{' '}
                to unlock advanced Visitor Analytics.
              </p>
            </Banner>
          </div>
        ) : null}
        <div className={`${isLimited ? 'disabled-element' : ''} analytics-container mt-16`}>
          <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
            {dataChart.map((item, index) => {
              return (
                <Card key={index}>
                  <div className="analytics-items-title">
                    <Icon source={item.icon} tone="base" />
                    <Text fontWeight="medium" as="h5" variant="headingSm">
                      {item.title}
                    </Text>
                  </div>
                  <div className="mt-8">
                    {isLoading ? (
                      <SkeletonDisplayText />
                    ) : (
                      <div>
                        <Text as="h5" variant="heading2xl" fontWeight="regular">
                          {item.value}
                        </Text>
                      </div>
                    )}
                  </div>
                </Card>
              );
            })}
          </InlineGrid>
        </div>
      </Card>
    </AnalyticsStyled>
  );
};

export default memo(Analytics);
