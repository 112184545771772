import { SessionBannerStyled } from '@/components/SessionBanner/styled';
import { Banner, BannerTone } from '@shopify/polaris';
import { ReactNode, useEffect, useState } from 'react';

interface IProps {
  children: ReactNode;
  title: string;
  sessionKey: string;
  tone?: BannerTone;
}

export default function SessionBanner(props: IProps) {
  const [isShow, setIsShow] = useState(false);

  const handleHideBanner = () => {
    sessionStorage.setItem(props.sessionKey, 'true');
    setIsShow(false);
  };

  useEffect(() => {
    if (!sessionStorage.getItem(props.sessionKey)) {
      setIsShow(true);
    }
  }, [props.sessionKey]);

  return isShow ? (
    <SessionBannerStyled className="mb-16">
      <Banner title={props.title} onDismiss={handleHideBanner} tone={props?.tone}>
        {props.children}
      </Banner>
    </SessionBannerStyled>
  ) : null;
}
