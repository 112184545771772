import AutoCompleteWithTag from '@/components/AutoCompleteWithTag';
import BaseCollapsible from '@/components/BaseCollapsible/BaseCollapsible';
import BoldText from '@/components/BoldText';
import { WaringIcon } from '@/components/Icons/Waring';
import TimePicker from '@/components/TimePicker';
import TimeZoneSelect from '@/components/TimeZoneSelect';
import UpgradeText from '@/components/UpgradeText';
import SettingToggle from '@/components/settingToggle';
import { PricingPlan, TargetPage, WorkingDayType } from '@/constants/enum';
import { dayOfWeekOptions } from '@/constants/options';
import { usePages } from '@/hooks/usePages';
import { apiCaller } from '@/redux/query';
import widgetSlice, { triggerSelector } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { LegacyStack, RadioButton, TextField, Tooltip } from '@shopify/polaris';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepFourStyled } from './styled';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const pageTargetConfig = [
  {
    label: 'All pages',
    value: TargetPage.AllPage,
  },
  {
    label: 'Specific pages',
    value: TargetPage.SpecificPages,
  },
  {
    label: 'Exclude pages',
    value: TargetPage.ExcludePages,
  },
];

const workingDaysConfig = [
  {
    label: 'All days',
    value: WorkingDayType.AllDay,
  },
  {
    label: 'Custom time',
    value: WorkingDayType.CustomDay,
  },
];

const StepFour = () => {
  const isSkip = useSelector(isSkipApiSelector);
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });
  const listPage = usePages();
  const dispatch = useDispatch();
  const trigger = useSelector(triggerSelector);
  const handleChange = useCallback(
    (key: keyof typeof trigger) => (value: string[] | string | boolean | IParamsApi.IRule[] | TargetPage) => {
      dispatch(
        widgetSlice.actions.handleTrigger({
          ...trigger,
          [key]: value,
        }),
      );
    },
    [dispatch, trigger],
  );
  const handleChangeWorkingDaysType = (value: WorkingDayType) => {
    dispatch(
      widgetSlice.actions.handleTrigger({
        ...trigger,
        isUseWorkingTime: value,
      }),
    );
  };

  return (
    <StepFourStyled>
      <SettingToggle
        title="Widget status"
        settingToggleProps={{
          action: {
            onAction: () => handleChange('active')(!trigger.active),
          },
          enabled: trigger.active,
        }}
      />
      <div className="mt-8">
        <BaseCollapsible id="trigger" open={!trigger.active}>
          <div className="turned-off">
            <WaringIcon />
            Widget turned off
          </div>
        </BaseCollapsible>
      </div>
      <BaseCollapsible id="trigger" open={trigger.active}>
        <div className="trigger-settings-container">
          <div className="display-after">
            {/* @Polaris */}
            <label>Display after</label>
            <div className="text-filed">
              <TextField
                type="number"
                label=""
                value={trigger.displayAfterSecondValue}
                onChange={(value) =>
                  handleChange('displayAfterSecondValue')(Math.max(Math.min(Number(value), 100), 0).toString())
                }
                autoComplete="off"
              />
            </div>
            <label>seconds on the page</label>
          </div>
          <div className="display-after mt-16">
            <label>Display after</label>
            <div className="text-filed">
              <TextField
                type="number"
                label=""
                value={trigger.displayAfterScrollValue}
                onChange={(value) =>
                  handleChange('displayAfterScrollValue')(Math.max(Math.min(Number(value), 100), 0).toString())
                }
                autoComplete="off"
              />{' '}
              {/* @Polaris */}
            </div>
            <label>% on page</label>
          </div>
        </div>
      </BaseCollapsible>

      <div className="mt-16">
        <Tooltip content="Available on Premium plan">
          <BoldText>Page target</BoldText>
        </Tooltip>
        {dataSetting?.data?.plan !== PricingPlan.Premium ? (
          <UpgradeText title="Upgrade to Premium plan" description="to unlock unlimited page target." />
        ) : null}
        <LegacyStack vertical={false}>
          {pageTargetConfig.map((item) => {
            return (
              <RadioButton
                key={item.value}
                label={item.label}
                // helpText="Customers will only be able to check out as guests."
                checked={trigger.pageTargetType === item.value}
                id={`${item.value}-page`}
                name={`${item.value}-page`}
                onChange={() => handleChange('pageTargetType')(item.value)}
                disabled={dataSetting?.data?.plan !== PricingPlan.Premium}
              />
            );
          })}
        </LegacyStack>
        <BaseCollapsible id="target-page" open={trigger.pageTargetType !== TargetPage.AllPage}>
          <div className="mt-16">
            <AutoCompleteWithTag
              allowMultiple
              label="Target page"
              placeholder="Select target page"
              labelHidden
              options={listPage.pageOptions}
              selectedOptions={
                trigger.pageTargetType === TargetPage.SpecificPages
                  ? trigger.specificPages.split(',').filter(Boolean)
                  : trigger.excludePages.split(',').filter(Boolean)
              }
              setSelectedOptions={(value) => {
                handleChange(trigger.pageTargetType === TargetPage.SpecificPages ? 'specificPages' : 'excludePages')(
                  value.join(','),
                );
              }}
              disable={dataSetting?.data?.plan !== PricingPlan.Premium}
            />
          </div>
        </BaseCollapsible>
      </div>

      <div className="mt-16">
        <div className="mb-8">
          <BoldText>Timezone</BoldText>
        </div>

        <TimeZoneSelect labelHidden label="Timezone" onChange={handleChange('timezone')} value={trigger.timezone} />
      </div>

      <div className="mt-16">
        <Tooltip content="Available on Premium plan">
          <BoldText>Working days</BoldText>
        </Tooltip>
        {dataSetting?.data?.plan !== PricingPlan.Premium ? (
          <UpgradeText title="Upgrade to Premium plan" description="to customize working time." />
        ) : null}
        <LegacyStack vertical={false}>
          {workingDaysConfig.map((item) => {
            return (
              <RadioButton
                key={item.value}
                label={item.label}
                // helpText="Customers will be able to check out with a customer account or as a guest."
                id={`${item.value}-days`}
                name={`${item.value}-days`}
                checked={trigger.isUseWorkingTime === item.value}
                onChange={() => handleChangeWorkingDaysType(item.value)}
                disabled={dataSetting?.data?.plan !== PricingPlan.Premium}
              />
            );
          })}
        </LegacyStack>
        <BaseCollapsible id="working-days" open={trigger.isUseWorkingTime === WorkingDayType.CustomDay}>
          <div className="mt-16">
            <AutoCompleteWithTag
              allowMultiple
              label="Working days"
              placeholder="Select days"
              labelHidden
              options={dayOfWeekOptions}
              selectedOptions={trigger.workingDays.map((item) => item.toString())}
              setSelectedOptions={(value) => handleChange('workingDays')(value)}
              disable={dataSetting?.data?.plan !== PricingPlan.Premium}
            />
          </div>
          <div className="mt-16">
            <div className="d-flex">
              <TimePicker
                value={trigger.startWorkingTime}
                onChange={handleChange('startWorkingTime')}
                label="Start working time"
                disabled={dataSetting?.data?.plan !== PricingPlan.Premium}
              />
              <div className="ml-16">
                <TimePicker
                  value={trigger.endWorkingTime}
                  onChange={handleChange('endWorkingTime')}
                  label="End working time"
                  disabled={dataSetting?.data?.plan !== PricingPlan.Premium}
                />
              </div>
            </div>
          </div>
        </BaseCollapsible>
      </div>
    </StepFourStyled>
  );
};

export default StepFour;
