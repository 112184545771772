import styled from 'styled-components';

interface IProps {
  borderRadius: string;
}

export const StepOneStyled = styled.div<IProps>`
  .channel-container-select {
    border-radius: 50%;
  }
  div[class='channel-container-select']:not(:hover),
  div[class='channel-container-select disabled-select'] {
    .color-element {
      fill: #bdbdbd;
      color: #bdbdbd;
    }
    path {
      fill: white;
    }
    polygon {
      fill: white;
    }
  }
  .add-custom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #bdbdbd;
    border-radius: 50%;
    margin-bottom: 6px;
    cursor: pointer;
    .Polaris-Icon {
      svg {
        fill: #ffffff;
        transform: scale(2.5);
      }
    }
  }
  .custom-card-header {
    border-bottom: none !important;
  }
`;
