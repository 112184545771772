import { PATH } from '@/constants/path';
import { images } from '@/asset';
import { Subscription } from '@/constants/enum';
import { useNavigate } from 'react-router-dom';

interface IProps {
  title: string;
  description: string;
  style?: React.CSSProperties;
}

const UpgradeText = ({ title, description, style }: IProps) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex mb-4" style={style}>
      <img className="mr-4" src={images.crown} alt="vip" />
      <p>
        <span
          onClick={() => {
            navigate(PATH.PRICING_PLAN, { state: Subscription.Monthly });
          }}
          style={{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--p-color-text-emphasis)' }}
        >
          {title || 'Upgrade to Premium plan'}
        </span>
        <span style={{ marginLeft: 4, color: '#9b6d00' }}>{description || 'to unlock feature.'}</span>
      </p>
    </div>
  );
};

export default UpgradeText;
