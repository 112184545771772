import React from 'react';
import { PromotionsCardStyled } from './styled';
import { AlertTriangleIcon, ShieldCheckMarkIcon, CheckIcon, XIcon } from '@shopify/polaris-icons';
import {
  BlockStack,
  Card,
  Icon,
  IconProps,
  InlineGrid,
  InlineStack,
  Text,
  TextProps,
  Badge,
  Box,
  Button,
  Tooltip,
} from '@shopify/polaris';
import { BadgeProps } from '@shopify/polaris/build/ts/src/components/Badge';
import { sessionSlice } from '@/redux/slice/session.slice';
import { isSubmittedPromoSyncTrackSelector } from '@/redux/slice/session.slice';
import { useDispatch, useSelector } from 'react-redux';
import { apiCaller } from '@/redux/query';
import { Ga4Event } from '@/constants/enum';
import bannerSlice from '@/redux/slice/banner.slice';

interface IPromotionsItemDataProps {
  title: {
    text: string;
    tone: TextProps['tone'];
  };
  iconTitle: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    tone: IconProps['tone'];
  };
  content: string;
  subContent: {
    items: string[];
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    tone: IconProps['tone'];
  };
  badge?: {
    text: string;
    tone: BadgeProps['tone'];
  };
  additionalText: string;
  button?: {
    text: string;
    link: string;
  };
}

const promotionsData: IPromotionsItemDataProps[] = [
  {
    title: {
      text: 'You might not know this!',
      tone: 'success',
    },
    iconTitle: {
      icon: AlertTriangleIcon,
      tone: 'warning',
    },
    content: 'Did you know that PayPal and Stripe can hold your funds and limit your account?',
    subContent: {
      items: ['They can hold your funds for up to 21 days', 'Limit your accounts', 'Ban your account permanently'],
      icon: AlertTriangleIcon,
      tone: 'warning',
    },
    additionalText: 'PayPal or Stripe may randomly check if your orders are being delivered.',
  },
  {
    title: {
      text: 'Strong Account',
      tone: 'success',
    },
    iconTitle: {
      icon: ShieldCheckMarkIcon,
      tone: 'success',
    },
    badge: {
      text: 'Verified by PayPal',
      tone: 'success',
    },
    content: 'Strong PayPal or Stripe account looks like this:',
    subContent: {
      items: ['No random holds or bans', 'Fewer chargebacks and holds', 'A better relationship between PayPal and Stripe'],
      icon: CheckIcon,
      tone: 'success',
    },
    additionalText: 'You can automate this by sending proof of delivery to PayPal using Synctrack.',
    button: {
      text: 'Yes, help me strengthen my account',
      link: 'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=fbchat&utm_medium=homepromotion',
    },
  },
];

const PromotionsCard = () => {
  const dispatch = useDispatch();
  const [sendGA4Event] = apiCaller.useSendGA4EventMutation();
  const isSubmittedSyncTrack = useSelector(isSubmittedPromoSyncTrackSelector);

  const handleClickSynctrackBtn = () => {
    const dataSubmit = {
      eventName: Ga4Event.PromoSyncTrackImpression,
      params: {},
    };
    if (!isSubmittedSyncTrack) {
      sendGA4Event(dataSubmit);
      dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedSyncTrack', value: true }));
    }
    window.open('https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=fbchat&utm_medium=homepromotion', '_blank');
  };
  return (
    <PromotionsCardStyled>
      <Card>
        <div className="d-flex content-between mb-16">
          <div className="w-full">
            <Text as="h3" variant="headingMd">
              Promotions
            </Text>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'isVisiblePromotionCard', value: false }));
            }}
          >
            <Tooltip content="Dismiss">
              <Icon source={XIcon} tone="base" />
            </Tooltip>
          </div>
        </div>

        <InlineGrid gap="400" columns={{ xs: 1, md: 2 }}>
          {promotionsData.map((item, idx) => (
            <Card key={item.title.text}>
              <BlockStack gap="300">
                <InlineStack align="space-between" blockAlign="center" gap="050">
                  <InlineStack gap="050">
                    <Icon source={item.iconTitle.icon} tone={item.iconTitle.tone} />

                    <InlineStack align="center" gap="050" blockAlign="center">
                      <Text id={idx === 0 ? 'warning' : ''} as="h4" variant="headingMd" tone={item.title.tone}>
                        {item.title.text}
                      </Text>
                    </InlineStack>
                  </InlineStack>
                  {item?.badge && <Badge tone={item.badge.tone}>{item.badge.text}</Badge>}
                </InlineStack>

                <Box paddingInlineStart="500">
                  <BlockStack gap="200">
                    <Text as="h6" variant="headingMd">
                      {item.content}
                    </Text>
                    {item.subContent.items.map((sub) => (
                      <div key={sub} style={{ display: 'flex', gap: '5px' }}>
                        <Icon source={item.subContent.icon} tone={item.subContent.tone} />
                        <div style={{ flex: 1 }}>
                          <Text as="span">{sub}</Text>
                        </div>
                      </div>
                    ))}

                    <Text as="span">{item.additionalText}</Text>

                    {item?.button && (
                      <Button onClick={handleClickSynctrackBtn} size="large" variant="primary">
                        {item.button?.text}
                      </Button>
                    )}
                  </BlockStack>
                </Box>
              </BlockStack>
            </Card>
          ))}
        </InlineGrid>
      </Card>
    </PromotionsCardStyled>
  );
};

export default PromotionsCard;
