import CustomCard from '@/components/customCard';
import { ICon } from '@/constants/enum';
import { IIconDefault } from '@/pages/NewWidget/listIconDefault';
import { CardGroupBtnStyled } from '@/pages/NewWidget/StepOne/components/CardGroupBtn/styled';
import { customizeSelected } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { Icon, Tooltip } from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';

interface IProps {
  title: string;
  listIcon: IIconDefault[];
  channelsSelected: IParamsApi.IChannelButton[];
  isLimited: boolean;
  isDisabledSelect: boolean;
  handleIconClick: (keyValue: ICon, value: IParamsApi.IChannelButton, customLinkId?: number) => void;
  description?: string;
  children?: React.ReactNode;
}

const CardGroupBtn = ({
  title,
  listIcon,
  channelsSelected,
  isLimited,
  isDisabledSelect,
  handleIconClick,
  description,
  children,
}: IProps) => {
  const customize = useSelector(customizeSelected);
  return (
    <CustomCard
      title={
        <div className="d-flex align-items-center">
          <b>{title}</b>
          <Tooltip content={description}>
            <Icon source={InfoIcon} tone="subdued" />
          </Tooltip>
        </div>
      }
      padding={'300'}
    >
      {children}
      <div className="d-flex flex-wrap">
        {listIcon.map((item) => {
          const isSelected = channelsSelected.find((channel) => channel.channel === item.keyValue);
          const isDisableFormContact = isLimited && item.keyValue === ICon.ContactForm;
          const isDisabled = !isSelected && (isDisableFormContact || isDisabledSelect);
          return (
            <CardGroupBtnStyled key={`${item.keyValue} widget name`} borderRadius={customize.buttonStyle}>
              <div
                className={`channel-container-select${isSelected ? ' selected' : ''}${isDisabled ? ' disabled-select' : ''}`}
                onClick={() => {
                  if (isDisabled) return;
                  handleIconClick(item.keyValue, item.value, isSelected?.customLinkId);
                }}
                style={{
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
              >
                {isDisableFormContact && !isSelected ? (
                  <Tooltip content={'Available on Pro and Premium plan'}>
                    <div className={`icon-container ${!isSelected ? 'disabled-select' : ''}`}>{item.icon}</div>
                  </Tooltip>
                ) : (
                  <div className={`icon-container ${!isSelected && isDisabledSelect ? 'disabled-select' : ''}`}>{item.icon}</div>
                )}

                <div className="text-container">{item.label}</div>
              </div>
            </CardGroupBtnStyled>
          );
        })}
      </div>
    </CustomCard>
  );
};

export default CardGroupBtn;
